import { authHeader, authResponse } from '../components/AuthProvider';

const useRequest = () => {
    const request = async (path, data, rconfig = {}) => {
        const settings = {
            method: rconfig.method || 'POST',
            headers: rconfig.header || authHeader()
        };
    
        if (data !== false) {
            settings.body = JSON.stringify(data);
        }
        const response = await fetch(process.env.REACT_APP_API_PATH + path, settings);
    
        if (!response.ok) {
            if (authResponse(response)) {
                return false;
            }
            
            throw new Error(response.statusText);
        }
    
        const json = await response.json();
        return json;
    };
    
    const requestForm = async (path, data, rconfig = {}) => {
        const settings = {
            method: rconfig.method || 'POST',
            headers: rconfig.header || authHeader()
        };
    
        delete settings.headers['Content-Type'];
    
        if (data !== false) {
            settings.body = data;
        }
        const response = await fetch(process.env.REACT_APP_API_PATH + path, settings);
    
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    
        const json = await response.json();
        return json;
    };
    
    return { request, requestForm };
};

export default useRequest;
